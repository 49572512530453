<template>
  <div class="big-form">
    <div class="login-form">
      <p
        :style="{
          color: $store.getters.color.fontColor,
          backgroundColor: $store.getters.color.color1,
        }"
      >
        {{ $t("password.resetPwd") }}
      </p>
      <div class="form-container">
        <span class="information">
          {{ $t("password.resetPwdInformation") }} </span
        ><br />
        <label for="username" class="username-label">{{
          $t("label.email")
        }}</label>
        <input
          type="text"
          id="username"
          v-model="email"
          @keypress.enter="submitResetRequest()"
          required
        />
        <button
          type="button"
          @click="submitResetRequest()"
          class="login-button"
          :disabled="checkForm"
        >
          {{ $t("buttons.go") }}
        </button>
      </div>
    </div>
    <br />
    <br />
  </div>
</template>

<script>
import HandleApi from "../apiHandle";

export default {
  name: "NewPassword",
  data() {
    return {
      email: "",
    };
  },
  methods: {
    submitResetRequest: async function () {
      try {
        await HandleApi.resetPwd({ email: this.email });
        this.$store.dispatch(
          "push_notification_message",
          this.$t("notification.sent", {
            object: this.$t("notification.resetEmail"),
          })
        );
        this.$router.push("/login");
      } catch (err) {
        this.$store.dispatch(
          "push_error_message",
          err.response.data != "" ? err.response.data : err
        );
      }
    },
  },
  computed: {
    checkForm: function () {
      if (!this.email) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped>
.big-form {
  text-align: center;
}

.login-form {
  margin: auto auto;
  display: inline-block;
  border-radius: 20px;
  box-shadow: 5px 5px 5px 5px lightgrey;
}

.login-form div {
  padding: 10px;
}

label {
  margin-right: 10px;
}

p {
  margin: 0;
  padding: 20px;
  font-size: 25px;
  color: white;
  font-weight: bold;
  background-color: #00909f;
  border-radius: 20px 20px 0px 0px;
  border: 2px solid lightgrey;
  text-align: left;
}

.information {
  grid-column-start: 1;
  grid-column-end: span 2;
}

.form-container {
  border: 2px solid lightgrey;
  border-top: none;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 20px;
  grid-row-gap: 30px;
  border-radius: 0 0 20px 20px;
}

.form-container .username-label {
  grid-column-start: 1;
  grid-column-end: span 1;
  grid-row-start: 2;
  grid-row-end: span 1;
  margin-left: 10px;
  align-self: center;
  justify-self: center;
}

.form-container #username {
  grid-column-start: 2;
  grid-column-end: span 1;
  grid-row-start: 2;
  grid-row-end: span 1;
  margin-right: 10px;
}

.form-container .password-label {
  grid-column-start: 1;
  grid-column-end: span 1;
  grid-row-start: 3;
  grid-row-end: span 1;
  margin-left: 10px;
  align-self: center;
  justify-self: center;
}

.form-container #password {
  grid-column-start: 2;
  grid-column-end: span 1;
  grid-row-start: 3;
  grid-row-end: span 1;
  margin-right: 10px;
}

.error-message {
  border: 2px solid red;
  color: red;
  background-color: #ecb2b2;
  margin: 0 auto;
  border-radius: 10px;
  grid-row-start: 4;
  grid-row-end: span 1;
  grid-column-start: 1;
  grid-column-end: span 2;
  font-size: 18px;
}

.login-button {
  grid-column-start: 1;
  grid-column-end: span 2;
  color: white;
  border-radius: 10px;
  border: none;
  background-color: #008467;
  font-weight: bold;
  font-size: 20px;
  padding: 10px;
  width: 100px;
  align-self: center;
  justify-self: center;
  grid-row-start: 3;
  grid-row-end: span 1;
}

.login-button:disabled {
  background-color: rgb(139, 196, 169);
}

a {
  color: black;
  text-decoration: none;
  margin: 30px;
}

a:hover {
  text-decoration: underline;
}
</style>
